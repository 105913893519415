import React, { useState } from "react"
import { WebformElementWrapper, useWebformElement } from "gatsby-drupal-webform"
import { AiOutlineLink } from "react-icons/ai"
import { FormattedMessage } from "react-intl"

const WebformInputFileEn = ({ element, error }) => (
  <WebformInputFile webform_id="contact_us" element={element} error={error} />
)

const WebformInputFileFr = ({ element, error }) => (
  <WebformInputFile
    webform_id="contactez_nous"
    element={element}
    error={error}
  />
)

const WebformInputFile = ({ webform_id, element, error }) => {
  const id = `form-${element.name}`.replace(/[_\s]/g, "-")
  const [filename, setFilename] = useState(null)
  const [val, setVal] = useState("")

  const handleChange = (e) => {
    setVal(e.target.value)
  }

  const uploadFile = async (e) => {
    const file = e.target?.files[0]

    if (!file) return

    let formData = new FormData()

    formData.append(`file`, file)

    const res = await fetch(
      `${process.env.GATSBY_API_URL}/webform_rest/${webform_id}/upload/piece_jointe?_format=json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
          "Content-Disposition": 'file; filename="' + file?.name + '"',
        },
        body: formData,
      }
    )

    let data = await res.json()
    const fid = data?.fid[0]

    setFilename(file?.name)
    setVal(fid.value)
  }

  const [inputProps, settings] = useWebformElement(element, {
    className: "form-control",
    type: "file",
    id,
  })

  return (
    <WebformElementWrapper settings={settings} error={error} labelFor={id}>
      <label htmlFor="form-piece-jointe">
        {!filename && <FormattedMessage id="Attachment" />}
        <input onChange={(e) => uploadFile(e)} {...inputProps} />
        <input value={val} onChange={handleChange} name={element.name} hidden />
        <span>
          {filename || <FormattedMessage id="Browse" />}
          <AiOutlineLink size="13" style={{ color: "#f65890" }} />
        </span>
      </label>
    </WebformElementWrapper>
  )
}

export { WebformInputFileEn, WebformInputFileFr }
