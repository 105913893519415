import React from "react"
import Recaptcha from "react-recaptcha"

const InvisibleRecaptcha = (props) => {
    // load on callback verify
    const verifyCallback = (token) => {
        props.onVerified(token)
        console.log("verifyCallback-->", token)
    }
    return (
        <Recaptcha
            className="g-recaptcha"
            sitekey="6Lcq-8IZAAAAAAsVvsWeL-vmUunpj8rGoIX5t8mX"
            verifyCallback={verifyCallback}
        />
    )
}
export default InvisibleRecaptcha
