import React, { useState } from "react"
import { graphql } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"
import { FormattedMessage } from "react-intl"
import Webform from "gatsby-drupal-webform"

import Layout from "components/layout"
import SEO from "components/seo"
import { DrupalAssociation } from "components/paragraphs"
import {
  InvisibleRecaptcha,
  WebformInputFileFr,
  WebformInputFileEn,
  WebformRadios,
} from "components/contact"
import { Wysiwyg } from "components/helpers"
import {
  AiFillCheckCircle,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai"

import "scss/contact.scss"
import "scss/tabs.scss"
import "scss/form.scss"

const ContactPage = ({ data }) => {
  const [succeed, setSuccess] = useState(false)
  const [verified, setVerified] = useState("")
  const { locale } = useLocalization()

  if (!data) return null

  const { webformFr, webformEn } = data
  let webform = locale === "fr" ? webformFr : webformEn

  // Patch: add webform id manually
  // Can't get webform id using JSON:API
  // Can't Submit Form if no drupal_internal__id provided
  webform["drupal_internal__id"] =
    locale === "fr" ? "contactez_nous" : "contact_us"

  const {
    nodeHomePage: {
      relationships: { field_drupal_association },
    },
    nodeContact: {
      title,
      body,
      relationships: { field_contact_information },
    },
  } = data

  const metadata = {
    url: "/contact",
    title: title,
    description: body?.processed,
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="header-blue">
        <div className="contact-wrapper">
          <div className="form-bkg"></div>
          <div className="container">
            <div
              className="contact__primary"
              data-sal-duration="1000"
              data-sal="zoom-in"
            >
              <h1>{title}</h1>
              <Wysiwyg {...body} />
            </div>
            <div className="contact__columns">
              <div
                data-sal-duration="1000"
                data-sal="slide-right"
                className={`contact__form ${
                  succeed && "contact__form--succeed sal-animate"
                }`}
              >
                {succeed ? (
                  <>
                    <AiFillCheckCircle color="#4bb543" size="4em" />
                    <h2 className="contact__message">
                      <FormattedMessage id="Your message has been sent successfully" />
                    </h2>
                  </>
                ) : (
                  <>
                    <Webform
                      className="form form--contact"
                      webform={webform}
                      customComponents={{
                        webform_document_file:
                          locale === "fr"
                            ? WebformInputFileFr
                            : WebformInputFileEn,
                        radios: WebformRadios,
                      }}
                      endpoint={`${process.env.GATSBY_API_URL}/react_webform_backend/submit`}
                      onSuccess={() => setSuccess(true)}
                      extraData={{ verified }}
                    />
                    <InvisibleRecaptcha
                      onVerified={(token) => setVerified(token)}
                    />
                  </>
                )}
              </div>
              <div
                className="locations"
                data-sal-duration="1000"
                data-sal="slide-left"
              >
                <h2>
                  <FormattedMessage id="Offices in Paris" />
                  <br />
                  <FormattedMessage id="and Casablanca" />
                </h2>
                <div className="locations__items">
                  <div className="locations__wrap">
                    {field_contact_information.map((contactInfo) => (
                      <div className="location__item" key={contactInfo.id}>
                        <div className="location__title">
                          {contactInfo.field_title}
                        </div>
                        <div className="location__adress">
                          <AiOutlineMail
                            size="17"
                            style={{ color: "#f4699c" }}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: contactInfo.field_adress.processed,
                            }}
                          />
                        </div>
                        <div className="location__phone">
                          <AiOutlinePhone
                            size="17"
                            style={{ color: "#f4699c" }}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: contactInfo.field_phone_number.processed,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="maps" data-sal-duration="1000" data-sal="zoom-in">
              <div className="tabs__warpper">
                <input
                  className="radio"
                  id="one"
                  name="group"
                  type="radio"
                  defaultChecked
                />
                <input className="radio" id="two" name="group" type="radio" />
                <div className="tabs">
                  <label className="tab" id="one-tab" htmlFor="one">
                    Paris
                  </label>
                  <label className="tab" id="two-tab" htmlFor="two">
                    Casablanca
                  </label>
                </div>
                <div className="panels">
                  <div className="panel" id="one-panel">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          title="gmap-paris"
                          src={`https://www.google.com/maps?q=128%20Rue%20la%20Bo%C3%A9tie%2C%2075008%20Paris%2C%20France&hl=${locale}&output=embed`}
                          width="100%"
                          height="450"
                          frameBorder="0"
                          style={{ border: 0 }}
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div className="panel" id="two-panel">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          title="gmap-casa"
                          src={`https://www.google.com/maps?q=Fullwave%20agency&hl=${locale}&output=embed`}
                          width="100%"
                          height="450"
                          frameBorder="0"
                          style={{ border: 0 }}
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DrupalAssociation {...field_drupal_association} />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery($locale: String = "fr") {
    webformFr: webformWebform(
      drupal_id: { eq: "bf037748-7d42-4a91-b0c4-b63732317a4b" }
    ) {
      drupal_id
      elements {
        name
        type
        attributes {
          name
          value
        }
        options {
          label
          value
        }
      }
    }
    webformEn: webformWebform(
      drupal_id: { eq: "786cb11f-171f-4548-a561-79e3f5777c71" }
    ) {
      drupal_id
      elements {
        name
        type
        attributes {
          name
          value
        }
        options {
          label
          value
        }
      }
    }
    nodeContact(
      langcode: { eq: $locale }
      path: { alias: { eq: "/contact" } }
    ) {
      title
      body {
        processed
      }
      relationships {
        field_contact_information {
          id
          field_title
          field_adress {
            processed
          }
          field_phone_number {
            processed
          }
        }
      }
    }
    nodeHomePage(
      langcode: { eq: $locale }
      path: { alias: { eq: "/homepage" } }
    ) {
      relationships {
        field_drupal_association {
          ...DrupalAssociationParagraph
        }
      }
    }
  }
`
