import React from "react"
import { useWebformElement } from "gatsby-drupal-webform"

const WebformRadios = ({ element, error }) => {
  const id = `form-${element.name}`.replace(/[_\s]/g, "-")

  const [inputProps] = useWebformElement(element, {
    className: "form-control",
    name: element.name,
    type: "radio",
  })

  return (
    <div className="form-group">
      {element.options &&
        element.options.map(({ label, value }) => (
          <label
            className="has-checkmark"
            htmlFor={`${id}-${value}`}
            key={`${id}-${value}`}
          >
            <input id={`${id}-${value}`} {...inputProps} value={value} />
            <span className="checkmark"></span>
            {label}
          </label>
        ))}
      {error && <div className="form-text invalid-feedback">{error}</div>}
    </div>
  )
}

export default WebformRadios
